// 增加新参数
export const appendParam = (url, param, isSearch = true, isHash = true) => {
    const urlObj = parseUrl(url)
    isSearch && (urlObj.search = searchHashAppend(urlObj.search, param))
    isHash && (urlObj.hash && searchHashAppend(urlObj.hash, param) || '')
    const { originPath, search, hash } = urlObj
    return `${originPath}${search}${hash}`
}

export const searchHashAppend = (target, append, filterEmpty) => {
    const param = paramStrToObj(target) || {}
    Object.assign(param, append)
    const paramStr = param && objToUrlParamStr(param, filterEmpty) || ''
    const idx = target.indexOf('?')
    const prefix = idx > -1 && target.substring(0, idx + 1) || `${target}?`
    return `${prefix}${paramStr}`
}


export const objToUrlParamStr = (param, filterEmpty = true) => {
    return objToUrlParamEncodeStr(param, filterEmpty, false)
}

export const objToUrlParamEncodeStr = (param, filterEmpty = true, encode = true) => {
    const arrays = []
    for (const key in param) {
        const val = param[key] === 0 ? '0' : (param[key] || '');
        (!filterEmpty || val) && arrays.push(`${key}=${encode ? encodeURIComponent(val) : val}`)
    }

    return arrays.join('&')
}

// 把url参数转换成对象格式
export const paramStrToObj = (paramUrl) => {
    if (!paramUrl) return {}

    const arrays = paramUrl.split('?') || []
    const paramStr = arrays[1] || ''
    const vals = paramStr && paramStr.split('&') || []
    const res = {}
    vals.map(item => {
        const kvs = item.split('=') || []
        res[kvs[0]] = kvs[1]
    })

    return res
}
export  function getUrlKey(name){
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

// 把hash和search组成一个新对象{ source:xx,originPath:xx,search:xx,hash:xx }
export const parseUrl = url => {
    const searchIdx = url.indexOf('?')
    const hashIdx = url.indexOf('#')
    const temp = { source: url, originPath: '', search: '', hash: '' }
    if (searchIdx < hashIdx) { // 存在hash部分,可能存在search
        if (searchIdx > -1) { // 存在search
            const originPath = url.substring(0, searchIdx)
            const search = url.substring(searchIdx, hashIdx)
            const hash = url.substring(hashIdx, url.length)
            Object.assign(temp, { originPath, search, hash })
        } else {
            const originPath = url.substring(0, hashIdx)
            const hash = url.substring(hashIdx, url.length)
            Object.assign(temp, { originPath, hash })
        }
    } else if (searchIdx > hashIdx) {
        if (hashIdx > 0) { // 不存在hash 但是存在search
            const originPath = url.substring(0, hashIdx)
            const hash = url.substring(hashIdx, url.length)
            Object.assign(temp, { originPath, hash })
        } else {
            const originPath = url.substring(0, searchIdx)
            const search = url.substring(searchIdx, url.length)
            Object.assign(temp, { originPath, search })
        }
    } else { // 不存在hash 也不存在search
        Object.assign(temp, { originPath: url })
    }

    return temp
}

