<template>
    <transition name="fade">
        <div class="costomerHome" v-show="state.drawer">
            <div class="header">
                <span @click="state.drawer = false">关闭窗口</span>
                <!-- <i class="bi bi-x-lg" @click="state.drawer = false"></i> -->
            </div>
            <iframe :src="state.url" frameborder="0"></iframe>
    </div>
    </transition>
   
</template>

<script setup>
import { reactive } from "vue"
let state = reactive({
    url:"",
    drawer:false,
    direction:"btt",
    isApp:false
})
let oWidth = document.body.clientWidth || document.documentElement.clientWidth;
if(oWidth < 767){
    state.isApp = true;
}else{
    state.isApp = false;
}
function show(){
    if(!state.isApp){
        state.drawer = !state.drawer;
    }
    clkonline();
}
function clkonline() {
    var onlineID = "";
    var onlineNe = "";
    var onlinePh = "";
    var ccccc = encodeURIComponent(window.location.href);
    // console.log(ccccc)
    // console.log(onlineNe+"_17630069135_h5")
    // return false
    var url =
        'https://ykf-webchat.7moor.com/wapchat.html?accessId=90d30c80-b31c-11ed-868b-c7b73208eb6b&fromUrl=http://aihua.com&urlTitle=爱华甄选售前&language=ZHCN'
    var otherparms = {
        "peerId": "10058804",
        // "clientId": "jf"+onlineID,
        "visible": true,
        "actionText": "发送",
        "actionTextColor": "#fff",
        "showCardInfoMsg": "1"
    }
    if(state.isApp){
        window.location.href = url + `&otherParams=` + JSON.stringify(otherparms)
    }else{
        state.url = url + `&otherParams=` + JSON.stringify(otherparms)
    }
}
defineExpose({
    show
})
</script>

<style lang="scss" scoped>
.header{
    background: white;
    height: 60px;
    padding:0 10px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}
.costomerHome{
    background: white;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 600px;
    z-index: 2000;
    iframe{
        width: 100%;
        height: calc( 100% - 60px );
    }
}
.fade-enter-active,
.fade-leave-active {
    bottom: 0;
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    bottom: -600px;
}
// .costomerHome-drawer-modal{
//     left: calc( 100% - 500px )!important;
//     width: 500px!important;
//     // height: 500px!important;
//     bottom: 0!important;
//     .costomerHome-drawer-con{

//     }
// }
// .costomerHome{
//     iframe{
//         width: 100%;
//         height: 100%;
//     }
//     .el-drawer__body{
//         padding: 0!important;
//     }
// }
// // .el-drawer{
// //     width: 500px;
// // }
// // .drawer-con{
    
// // }
</style>