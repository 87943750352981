<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-07 14:12:59
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-18 11:16:15
 * @FilePath: \website\src\pages\components\header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
   <div :class="props.data && !store.isApp?'scrollheader':'header'">
	   <div class="row no-gutters d-flex justify-content-center">
		   <div class="col-11 col-xl-8  d-flex justify-content-between header-container" :class="{'navOpen':state.drawer}">
				 <!-- style="margin-left: 18.5rem;" -->
				 <div class="left cursor-pointer" @click="scrollToAnchor('section1Ref')">
					<img v-if="!store.isApp" src="@/assets/logo.png" alt="" srcset="">
				</div>
				<div></div>
				<div class="right">
					<div class="lists height-100-all d-flex justify-content-between align-items-center">
						<template v-for="(item,index) in state.lists"  :key="index">
							<div class="list cursor-pointer" :class="{'active':state.activeName == item.name}" @click="scrollToAnchor(item.name,index)">
								{{item.title}}
								<div class="choicimg">
									<img v-show="state.clkindex == index" src="@/assets/choice.png" alt="" />
								</div>
							</div>
							
						</template>
					</div>
					<burger :lists="state.lists" @change="scrollToAnchor"/>
				</div>
			</div>
	   </div>
        
   </div>
   
</template>

<script setup>
import burger from "./burger"
import { reactive } from 'vue';
import { mainStore } from "@/sheep/store"
const store = mainStore()
const emits = defineEmits(['change'])
const props = defineProps({
	data:{
		type:Boolean,
		default:false
	}
})
// console.log(props.data)
let state = reactive({
    activeName:"section1Ref",
	showStand:"data",
    lists:[
        {
            name:"section1Ref",
            title:"首页"
        },
        {
            name:"section2Ref",
            title:"禅修"
        },
        {
            name:"section3Ref",
            title:"礼佛"
        }
		,
        // {
        //     name:"section4Ref",
        //     title:"下载体验"
        // },
        {
            name:"section5Ref",
            title:"联系我们"
        }

    ],
	clkindex:0
})
function scrollToAnchor(refStr,refIndex){
    state.activeName = refStr;
    emits("change",refStr)
	state.clkindex = refIndex
}

</script>

<style lang="scss" scoped>
	.header{
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
	    padding: 1.6rem 0;
	    
	}
	.scrollheader{
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		padding: 1.4rem 0 1rem;
		background: rgba(60,26,0,0.34);
	}
.header-container{
        .left{
			position: absolute;
			top: 0rem;
			left: 13%;
            img{
				width: 11rem;
                height:4rem;
            }
        }
        .right{
            flex: 1;
            max-width: 60rem;
			display: flex;
			align-items: center;
            
            .list{
                font-size: 2.5rem;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #fff;
                flex-shrink: 0;
                text-align: center;
                position: relative;
                min-width: 12.8rem;
            }
            .list.active{
                font-size: 2.8rem;
                font-family: PingFangSC-Medium, PingFang SC;
                color: #fff;
            }
			.choicimg{
				position: relative;
				bottom:0.6rem;
				left: 25%;
				width: 6.8rem;
				height: 1.4rem;
				img{
					width: 100%;
					height: 100%;
				}
			}
        }
    }

@media screen and (max-width: 767px) {
    .lists {
        display: none!important;
        
    }
    .right{
        display: flex;
        align-items: center;
        flex: none!important;
    }
}
// @media screen and (min-width: 768px) and (max-width: 1024px) {
//     .list {
//         width: 128px;
//     }
//     .lists {
//         display: flex!important;
//     }
//     #burger{
//         display: none!important;
//     }
// }

</style>