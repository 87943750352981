/*
 * @Author: guodm guodm@9856
 * @Date: 2023-08-17 15:27:14
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-17 15:38:47
 * @FilePath: \website\src\sheep\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { defineStore } from 'pinia'

export const mainStore = defineStore('main', {
    state: ()=>{
        return {
          isApp:false
        }
    },
    getters: {},
    actions: {
      isAppFun(){
        let oWidth = document.body.clientWidth || document.documentElement.clientWidth;
        if(oWidth < 767){
            this.isApp = true;
        }else{
          this.isApp = false;
        }
      }
    }
})