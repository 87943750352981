<template>
    <div class="visionHome">
		<div class="d-flex justify-content-center">
			<div class="vision col-11 col-xl-8 d-flex justify-content-center flex-column">
			    <div class="title-con">
					<img src="@/assets/lifu.png" alt="" />
					<div v-if="!store.isApp" class="downBtn">
						<div class="down col-11 col-xl-8">
						    <div class="content cursor-pointer">
						        <div class="downDmo"  @click="gotoApp">
						            <span>下载体验</span>
						            <img src="@/assets/arrow.png" alt="" srcset="">
						        </div>
								<div>
									<img class="code" src="@/assets/code.png" alt="" srcset="">
								</div>
						    </div>
						</div>
					</div>
				</div>
			    <div v-if="!store.isApp" class="list2s no-gutters d-flex justify-content-between">
			        <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
						<div class="lifubefimg1"></div>
			        </div>
			        <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
						<div class="lifubefimg2"></div>
			        </div>
			        <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<div class="lifubefimg3"></div>
			        </div>
					<div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<div class="lifubefimg4"></div>
					</div>
					<div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<div class="lifubefimg5"></div>
					</div>
					<div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<div class="lifubefimg6"></div>
					</div>
			    </div>
				<div v-else class="list2s no-gutters d-flex justify-content-between">
				    <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
				        <img class="lifubefimg" src="@/assets/iphlifu1.png" alt="" srcset="">
						<img class="lifuafrimg" src="@/assets/iphlifu11.png" alt="" srcset="">
				    </div>
				    <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
				        <img class="lifubefimg" src="@/assets/iphlifu2.png" alt="" srcset="">
				        <img class="lifuafrimg" src="@/assets/iphlifu22.png" alt="" srcset="">
				    </div>
				    <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<img class="lifubefimg" src="@/assets/iphlifu3.png" alt="" srcset="">
						<img class="lifuafrimg" src="@/assets/iphlifu33.png" alt="" srcset="">
				    </div>
					<div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<img class="lifubefimg" src="@/assets/iphlifu4.png" alt="" srcset="">
						<img class="lifuafrimg" src="@/assets/iphlifu44.png" alt="" srcset="">
					</div>
					<div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<img class="lifubefimg" src="@/assets/iphlifu5.png" alt="" srcset="">
						<img class="lifuafrimg" src="@/assets/iphlifu55.png" alt="" srcset="">
					</div>
					<div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<img class="lifubefimg" src="@/assets/iphlifu6.png" alt="" srcset="">
						<img class="lifuafrimg" src="@/assets/iphlifu66.png" alt="" srcset="">
					</div>
				</div>
				<div class="iphbomtree"></div>
				<div v-if="store.isApp" style="height: 4.3rem;"></div>
			</div>
		</div>
    </div>
</template>

<script setup>
import { mainStore } from "@/sheep/store"
const store = mainStore()
</script>

<style lang="scss" scoped>
	.iphbomtree{
		position: absolute;
		bottom: 1rem;
		left:-2rem;
		width: 10rem;
		height: 26.5rem;
		background: url(@/assets/iphbomtre.png) no-repeat;
		background-size: 100% 100%;
		z-index: 100;
	}
.visionHome{
	margin-top: -1px;
	background: url(@/assets/bgcbom.png) no-repeat;
	background-size: 100% 100%;
	padding: 0 6%;
	box-sizing: border-box;
    overflow: hidden;
    .vision{
        padding:0 0 8rem 0;
		text-align: center;
        .title-con{
            text-align: center;
            margin-bottom: 8.8rem;
			position: relative;
			.downBtn{
				position: absolute;
				top: 0;
				right: -22.3rem;
				.downDmo{
				    width: 20rem;
					height: 6rem;
					line-height: 6rem;
					text-align: center;
				    background: #BC3032;
				    border-radius: 1.2rem;
				    color:white;
				    font-size: 2.8rem;
				    img{
						width: 1.2rem;
				        height: 2rem;
				        margin-left: 1.2rem;
				    }
				}
				.down{
				    .code{
						margin-top: 2rem;
				        width: 20rem;
						height: 20rem;
				        opacity: 0;
				        transition: all .4s;
				    }
				}
				.content:hover{
				    .code{
				        opacity: 1;
				        transition: all .4s;
				    }
				}
			}
			
			img{
				width: 49.6rem;
				height: 9.7rem;
			}
        }
		.list2s{
			flex-wrap: wrap;
			justify-content: space-between;
		    .list{
				
		        transition: all .4s;
				
				.lifubefimg1{
					width: 60.6rem;
					height: 60.6rem;
					background: url(@/assets/lifu1.png) no-repeat;
					background-size: 100% 100%;
					// margin-right: 4rem;
					// margin-bottom: 5rem;
				}
				.lifubefimg2{
					width: 60.6rem;
					height: 60.6rem;
					background: url(@/assets/lifu2.png) no-repeat;
					background-size: 100% 100%;
					// margin-bottom: 5rem;
				}
				.lifubefimg3{
					width: 60.6rem;
					height: 60.6rem;
					background: url(@/assets/lifu3.png) no-repeat;
					background-size: 100% 100%;
					// margin-right: 4rem;
					// margin-bottom: 5rem;
				}
				.lifubefimg4{
					width: 60.6rem;
					height: 60.6rem;
					background: url(@/assets/lifu4.png) no-repeat;
					background-size: 100% 100%;
					margin-bottom: 5rem;
				}
				.lifubefimg5{
					width: 60.6rem;
					height: 60.6rem;
					background: url(@/assets/lifu5.png) no-repeat;
					background-size: 100% 100%;
					// margin-bottom: 5rem;
					// margin-right: 4rem;
				}
				.lifubefimg6{
					width: 60.6rem;
					height: 60.6rem;
					background: url(@/assets/lifu6.png) no-repeat;
					background-size: 100% 100%;
					// margin-bottom: 5rem;
				}
				.lifubefimg{
					display: block;
				}
				.lifuafrimg{
					width: 100%;
					margin-bottom: 5rem;
					display: none;
				}
		    }
		    .list:hover{
				.lifubefimg1{
					background: url(@/assets/lifu11.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.lifubefimg2{
					background: url(@/assets/lifu22.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.lifubefimg3{
					background: url(@/assets/lifu33.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.lifubefimg4{
					background: url(@/assets/lifu44.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.lifubefimg5{
					background: url(@/assets/lifu55.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.lifubefimg6{
					background: url(@/assets/lifu66.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.lifubefimg{
					display: none;
				}
				.lifuafrimg{
					display: block;
				}
		    }
		}
    }
}
@media screen and (max-width: 767px) {
    .visionHome{
		margin-top: 0px;
        padding: 0;
		background: url(@/assets/iphbombgc.png) no-repeat;
		background-size: 100% 100%;
        .vision{
            padding: 0;
            margin-top: 2rem;
            .title-con{
                text-align: center;
                margin-bottom: 0.4rem;
				img{
					width: 21.8rem;
					height: 4.3rem;
				}
            }
            .list2s{
            	flex-wrap: wrap;
            	justify-content: space-between;
                .list{
            		width: 33%;
                    transition: all .4s;
            		.lifubefimg{
            			width: 100%;
            			margin-bottom: 0rem;
            			display: block;
            		}
            		.lifuafrimg{
            			width: 100%;
            			margin-bottom: 0rem;
            			display: none;
            		}
                }
                .list:hover{
            		.lifubefimg{
            			display: none;
            		}
            		.lifuafrimg{
            			display: block;
            		}
                }
            }
        }
    }
   
}
</style>