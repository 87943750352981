<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-10 09:21:26
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-22 15:49:50
 * @FilePath: \website\src\pages\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="headerCon" ref="heander">
		<headerPage :data="pageData.showStand" @change="scrollToAnchor" />
	</div>
	<div ref="section1Ref">
		<banner @change="scrollToAnchor" />
	</div>
	<div ref="section2Ref">
		<vision />
	</div>
	<div ref="section3Ref">
		<swiperApp />
	</div>
	
	<div id="iphoneid" class="iphonetxt">
		目前只支持安卓系统
	</div>
	<div class="footCon" ref="section5Ref">
		<footnote />
		<img v-if="!store.isApp" class="bomtreeimg" src="@/assets/tree.png" alt="" />
	</div>
</template>
<script setup>
	import {
		ref,
		onMounted,
		reactive,
		nextTick
	} from 'vue';
	import headerPage from "./components/header"
	import banner from "./components/banner"
	import swiperApp from "./components/swiper-app"
	import vision from "./components/vision"
	import footnote from "./components/footnote"
	import WOW from 'wow.js'
	import {
		mainStore
	} from "@/sheep/store"
	import {
		getUrlKey
	} from "@/sheep/helper/utils"
	const store = mainStore()
	const heander = ref(null);
	const section1Ref = ref(null);
	const section2Ref = ref(null);
	const section3Ref = ref(null);
	const section4Ref = ref(null);
	const section5Ref = ref(null);
	const pageData = reactive({
		height: 0,
		showStand: false,
		timestand:true,
	})
	onMounted(() => {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.src = '//web.cdn.openinstall.io/openinstall.js';
		s.addEventListener('load', () => {
			var data = OpenInstall.parseUrlParams();
			new OpenInstall({
				appKey: "uwnng9", //appkey参数配置,需要自行替换对应的appkey
				preferWakeup:true,
				onready: function() {
					var m = this,
					button = document.getElementById("downclick");
					var u = navigator.userAgent;
					var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
					var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
					var iphoneid = document.getElementById("iphoneid")
					// console.log(isiOS)
					m.schemeWakeup();
					if(store.isApp){
						button.onclick = function() {
							if(isAndroid){
								m.wakeupOrInstall();
								return false;
							}else{
								if(pageData.timestand){
									pageData.timestand = false
									iphoneid.style.display = "block"
									var clicktime = setTimeout(()=>{
										iphoneid.style.display = "none"
										pageData.timestand = true
									},2000)
								}
								
							}
						}
					}
					
				}
			}, data);
		}, false);
		document.head.appendChild(s);
		
		window.addEventListener('scroll', (e) => {
			var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			if (scrollTop > 0) {
				pageData.showStand = true
			} else {
				pageData.showStand = false
			}
		})

		wowjsFn()
		store.isAppFun();
		if (!sessionStorage.getItem("sourceStr")) {
			let source = getUrlKey("source");
			let sourceRef = "";
			if (source == "experience") {
				sourceRef = "section4Ref";
			} else if (source == "contact") {
				sourceRef = "section5Ref";
			}
			if (sourceRef) {
				sessionStorage.setItem("sourceStr", sourceRef)
				setTimeout(() => {
					scrollToAnchor(sourceRef);
				}, 300)
			}
		}

	});
	const wowjsFn = () => {
		let wow = new WOW({
			boxClass: "wow", // animated element css class (default is wow)
			animateClass: "animated", // animation css class (default is animated)
			offset: 100, // distance to the element when triggering the animation (default is 0)
			mobile: true, // trigger animations on mobile devices (default is true)
			live: true, // act on asynchronously loaded content (default is true)
			callback: function(box) {
				// the callback is fired every time an animation is started
				// the argument that is passed in is the DOM node being animated
			},
			scrollContainer: null, // optional scroll container selector, otherwise use window
			resetAnimation: true,
		});
		wow.init()
	}

	// 锚点

	function scrollToAnchor(refDom, options = {}) {
		let newRef = eval(refDom);
		if (newRef.value) {
			scrollIntoView(newRef.value, options)
		}
	}
	/**
	 * 
	 * @param {*} elem 滚动的元素
	 * @param {*} options 滚动配置
	 */
	function scrollIntoView(elem, options) {
		options = {
			...options,
			duration: options.duration || 1000,
			offset: heander.value.clientHeight,
			ease: 'ease-out'
		}

		var scrollOffset = elem.getBoundingClientRect().top - options.offset,
			totalScrollOffset = window.scrollY + scrollOffset,
			duration = options.duration,
			startTime = Date.now(),
			distance,
			currentScrollPosition;

		requestAnimationFrame(function anim() {
			currentScrollPosition = window.scrollY;
			distance = totalScrollOffset - currentScrollPosition;

			var elapsed = Date.now() - startTime;
			var progress = Math.min(1, elapsed / duration);

			window.scrollTo(0, currentScrollPosition + (distance * progress));

			if (progress < 1 && distance !== 0) {
				requestAnimationFrame(anim);
			}
		});
	}
</script>

<style scoped lang="scss">
	
	.iphonetxt{
		position: fixed;
		top: 50%;
		left: 28%;
		width: 24rem;
		height: 4rem;
		text-align: center;
		line-height: 4rem;
		box-sizing: border-box;
		border-radius: 0.5rem;
		background-color: rgba(0, 0, 0, 0.7);
		font-size: 2rem;
		color: #fff;
		display: none;
	}
	.footCon {
		position: relative;

		.bomtreeimg {
			position: absolute;
			left: 0;
			bottom: 22.2rem;
			width: 57.9rem;
		}
	}

	.headerCon {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 10;

	}

	.container-dom {
		// position: fixed;
		// width: 100%;
		// height: calc( 100vh - v-bind('pageData.height') );
		// left: 0;
		// top: v-bind('pageData.height');
		// z-index: 10;
		// overflow-y: auto;
		// background: rgba(246, 246, 246, 1);
	}
</style>