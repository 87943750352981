<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-07 10:56:10
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-17 15:50:09
 * @FilePath: \website\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<index />
</template>

<script>
	import index from './pages/index.vue'

	export default {
		name: 'App',
		components: {
			index
		},
		mounted() {
			this.init(document.body.clientWidth || document.documentElement.clientWidth)
			window.onresize = () => {
				this.init(document.body.clientWidth || document.documentElement.clientWidth)
			}
			setTimeout(()=>{
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = "https://s9.cnzz.com/z.js?id=1281338929";
				document.head.appendChild(script);
			},500)
		},
		methods: {
			init(widthVal) {
				let html = document.getElementsByTagName("html")[0];
				let oWidth = widthVal;
				if (oWidth >= 1920) {
					html.style.fontSize = 1920 / 1920 * 10 + "px";
				} else if (oWidth >= 1024 && oWidth < 1920) {
					html.style.fontSize = 1024 / 1920 * 10 + "px";
				} else if (oWidth >= 768 && oWidth < 1024) {
					html.style.fontSize = 1024 / 1920 * 10 + "px";
				} else {
					html.style.fontSize = 768 / 1920 * 20 + "px";
				}
			}
		}
	}
</script>
<style src="wow.js/css/libs/animate.css"></style>
<style lang="scss">
	@import "@/sheep/scss/index.scss";

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		line-height: 1;
		background: rgba(246, 246, 246, 1);
	}

	/**隐藏滚动条 */
	::-webkit-scrollbar {
		width: 0 !important;
	}

	::-webkit-scrollbar {
		width: 0 !important;
		height: 0;
	}

	/* 修改滚动条样式 */
	/* ::-webkit-scrollbar {
    width: 10px;
    height: 1px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #E5E5E5;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: #ffffff;
} */
</style>