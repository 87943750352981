<template>
    <div class="burger-con">
        <div class="burger" :class="{'navOpen':state.drawer}" @click="closed">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="draw">
            <el-drawer
                v-model="state.drawer"
                :show-close="false"
                :with-header="false"
                :size="'40%'"
                :z-index="2000"
                @closed="closed"
            >
                <ul class="lists">
                    <li v-for="(item,index) in props.lists" :key="index"  @click="scrollToAnchor(item.name)">{{item.title}}</li>
                </ul>
            </el-drawer>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue';
const emits = defineEmits(['change'])
const props = defineProps({
    lists:{
        type:Array,
        default:()=>[]
    }
})
let state = reactive({
    drawer:false
})
function scrollToAnchor(refStr){
    emits("change",refStr)
    closed();
}
function closed(){
    state.drawer = !state.drawer;
}
</script>


<style lang="scss" scoped>
.draw{
    .lists{
        li{
            padding: 2rem 0;
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
        }
    }
}
.burger-con{
    position: relative;
}
.burger {
    display: none;
    z-index: 2001;
    width: 2.7rem;
    height: 2.1rem;
    margin: auto;
    transform: rotate(0deg);
    cursor: pointer
}

.burger span {
    display: block;
    position: absolute;
    height: 0.3rem;
    width: 100%;
    background-color: #000;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: background-color .3s linear,transform .1s linear
}

.burger span:nth-child(1) {
    top: 0;
}

.burger span:nth-child(2),.burger span:nth-child(3) {
    top: 0.8499rem
}

.burger span:nth-child(4) {
    top: 1.7001rem
}
.burger-con .navOpen{
    position: relative;
    span{
        background-color: #000;
        transition: background-color .1s ease-in,transform .2s ease-in;
        transition-delay: .1s
    }
    span:nth-child(1) {
        top: 6px;
        width: 0%;
        left: 50%
    }
    span:nth-child(2) {
        transform: rotate(45deg)
    }
    span:nth-child(3) {
        transform: rotate(-45deg)
    }
    span:nth-child(4) {
        top: 6px;
        width: 0%;
        left: 50%
    }
}

.navOpen:hover {
    transition-delay: unset
}

.navOpen:hover span {
    background-color: #000
}
@media screen and (max-width: 767px) {
    .burger{
        display: block!important;
    }
}
:deep(.el-drawer__body){
    padding: 5.7rem 15px;
}
</style>