<template>
    <div class="visionHome">
		<div class="d-flex justify-content-center">
			<div class="vision col-11 col-xl-8 d-flex justify-content-center flex-column">
			    <div class="title-con">
					<img src="@/assets/chanxiu.png" alt="" />
					<div v-if="!store.isApp" class="downBtn">
						<div class="down col-11 col-xl-8">
						    <div class="content cursor-pointer">
						        <div class="downDmo" @click="gotoApp">
						            <span>下载体验</span>
						            <img src="@/assets/arrow.png" alt="" srcset="">
						        </div>
								<div>
									<img class="code" src="@/assets/code.png" alt="" srcset="">
								</div>
						    </div>
						</div>
					</div>
				</div>
			    <div class="lists no-gutters d-flex justify-content-between">
					<div v-if="store.isApp" class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
					    <img class="beforeimg" src="@/assets/iphchan1.png" alt="" srcset="">
						<img class="afterimg" src="@/assets/iphchan11.png" alt="" srcset="">
					</div>
					<div v-else class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
						<div class="beforeimg1"></div>
					</div>
					<div v-if="store.isApp" class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
					    <img class="beforeimg" src="@/assets/iphchan2.png" alt="" srcset="">
					    <img class="afterimg" src="@/assets/iphchan22.png" alt="" srcset="">
					</div>
			        <div v-else class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
						<div class="beforeimg2"></div>
			        </div>
			        <div v-if="store.isApp" class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<img class="beforeimg" src="@/assets/iphchan3.png" alt="" srcset="">
						<img class="afterimg" src="@/assets/iphchan33.png" alt="" srcset="">
			        </div>
					<div v-else class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
						<div class="beforeimg3"></div>
					</div>
			    </div>
			</div>
		</div>
    </div>
</template>

<script setup>
import { mainStore } from "@/sheep/store"
const store = mainStore()
</script>

<style lang="scss" scoped>
.visionHome{
	background: url(@/assets/bgctop.png) no-repeat;
	background-size: 100% 100%;
	padding: 0 10%;
	box-sizing: border-box;
    overflow: hidden;
	margin-top: -2px;
    .vision{
        padding: 8rem 0;
		text-align: center;
        .title-con{
            text-align: center;
            margin-bottom: 8.8rem;
			position: relative;
			.downBtn{
				position: absolute;
				top: 0;
				right: -28rem;
				.downDmo{
				    width: 20rem;
					height: 6rem;
					line-height: 6rem;
					text-align: center;
				    background: #BC3032;
				    border-radius: 1.2rem;
				    color:white;
				    font-size: 2.8rem;
				    img{
						width: 1.2rem;
				        height: 2rem;
				        margin-left: 1.2rem;
				    }
			}
				.down{
				    .code{
						margin-top: 2rem;
				        width: 20rem;
						height: 20rem;
				        opacity: 0;
				        transition: all .4s;
				    }
				}
				.content:hover{
				    .code{
				        opacity: 1;
				        transition: all .4s;
				    }
				}
			}
			img{
				width: 49.6rem;
				height: 9.7rem;
			}
        }
        .lists{
            .list:nth-child(2){
                margin: 0 1.6rem;
            }
            .list{
				width: 33%;
                transition: all .4s;
				.beforeimg1{
					width: 39.6rem;
					height: 39.6rem;
					background: url(@/assets/chanxiu1.png) no-repeat;
					background-size: 100% 100%;
				}
				.beforeimg2{
					width: 39.6rem;
					height: 39.6rem;
					background: url(@/assets/chanxiu2.png) no-repeat;
					background-size: 100% 100%;
				}
				.beforeimg3{
					width: 39.6rem;
					height: 39.6rem;
					background: url(@/assets/chanxiu3.png) no-repeat;
					background-size: 100% 100%;
				}
				.beforeimg{
					width: 100%;
					display: block;
				}
				.afterimg{
					width: 100%;
					display: none;
				}
            }
            .list:hover{
				.beforeimg1{
					background: url(@/assets/chanxiu11.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.beforeimg2{
					background: url(@/assets/chanxiu22.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.beforeimg3{
					background: url(@/assets/chanxiu33.png) no-repeat;
					background-size: 100% 100%;
					transition:all 0.5s;
				}
				.beforeimg{
					display: none;
				}
				.afterimg{
					display: block;
				}
            }
        }
        
    }
}
@media screen and (max-width: 767px) {
    .visionHome{
		padding: 0;
        background: url(@/assets/iphtopbgc.png) no-repeat;
        background-size: 100% 100%;
		margin-top: 0px;
        .vision{
			padding:0;
            .title-con{
                text-align: center;
                margin-bottom: 0rem;
				img{
					width: 21.8rem;
					height: 4.3rem;
				}
            }
            .lists{
                .list{
                    margin:0!important;
                    width: 100%!important;
                    margin-top: $align-16!important;
                    transform: inherit;
                    .title{
                        justify-content: flex-start!important;
                        margin-bottom: $align-12;
                        img{
                            width: 2.3rem;
                            height: 2.3rem;
                            margin-right: 1rem;
                        }
                    }
                    .content{
                        padding: 0;
                        box-shadow:inherit;
                        .text{
                            margin-left: calc( 3rem + 1rem );
                        }
                        span{
                            font-size: $font-size-22;
                        }
                    }
                }
                .list:hover{
                    transform: inherit;
                }
            }
        }
    }
   
}
</style>